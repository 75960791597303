import React, { useState, useEffect } from "react";
import styled from "styled-components";

const TopMenu = ({ onFridgeSelect }) => {
  const [fridges, setFridges] = useState([]);
  const initialFridge = localStorage.getItem("selectedFridgeId") || "";
  const [selectedFridge, setSelectedFridge] = useState(initialFridge);
  const customerId = localStorage.getItem("customerId"); // Fetch customerId from localStorage

  const baseFridgeUrl = `https://boxmealadminapinew.azurewebsites.net/api/fridge/customer`;

  useEffect(() => {
    const fetchFridges = async () => {
      if (!customerId) {
        console.error("No customerId found in localStorage.");
        return;
      }

      try {
        const response = await fetch(`${baseFridgeUrl}/112`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setFridges(
          data.map((fridge) => ({ label: fridge.fridgeName, value: fridge.id }))
        );
      } catch (error) {
        console.error("Error fetching fridges:", error);
      }
    };

    fetchFridges();
  }, [customerId]);

  const handleFridgeChange = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected Fridge ID:", selectedValue);
    setSelectedFridge(selectedValue);
    localStorage.setItem("selectedFridgeId", selectedValue);
    onFridgeSelect(selectedValue);
  };

  return (
    <Container>
      <FridgeSelect value={selectedFridge} onChange={handleFridgeChange}>
        <option value="" disabled>
          Välj enhet
        </option>
        {fridges.map((fridge) => (
          <option key={fridge.value} value={fridge.value}>
            {fridge.label}
          </option>
        ))}
      </FridgeSelect>
    </Container>
  );
};

export default TopMenu;

// Styled components...

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 0 10px;
  height: 60px;
  width: 100%;
`;

const FridgeSelect = styled.select`
  color: white;
  background-color: #333;
  padding: 10px 15px;
  font-size: 16px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #444;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  appearance: none;
  outline: none;
  transition: all 0.3s ease;

  &:hover {
    border-color: #3fc48a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }

  &:focus {
    border-color: #3fc48a;
    box-shadow: 0 0 5px rgba(63, 196, 138, 0.8);
  }

  &::placeholder {
    color: gray;
  }

  & option {
    background-color: #333;
    color: white;
  }
`;
