import React, { useEffect } from "react";
import styled from "styled-components";
import { useCart } from "../context/CartContext";
import swishImage from "../assets/images/swish.png";
import { getSwishPaymentStatus } from "../services/SwishService";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  updateFridgeProductQuantities,
  saveShoppingHistory,
} from "../services/postPaymentServices";

const BASE_URL =
  "https://boxmealadminapinew.azurewebsites.net/api/ShoppingCart";
// const BASE_URL = "https://localhost:7255/api/ShoppingCart";

const ChoosePayment = () => {
  const { cartItems } = useCart();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedFridgeId } = location.state || {};
  const { user, token } = useAuth();
  let intervalId = null;

  // Function to check if the current tab is allowed to redirect
  const canRedirect = () => {
    const redirected = localStorage.getItem("paymentRedirected");
    console.log(`[ChoosePayment] canRedirect check. Redirected: ${redirected}`);
    return !redirected;
  };

  const computeTotalPrice = () =>
    cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  const initiateSwishPayment = async () => {
    console.log("[ChoosePayment] Initiating Swish payment...");

    const paymentData = {
      userId: "munir-user", //user.id,
      payeePaymentReference: "1235324876",
      callbackUrl: `https://boxmealadminapinew.azurewebsites.net/api/ShoppingCart/callback`,
      payeeAlias: "1235324876",
      amount: 1, // Replace with computeTotalPrice() for actual price
      currency: "SEK",
      message: "Shopping from mobile application",
      items: cartItems.map((item) => ({
        productId: item.id,
        productName: item.title,
        quantity: item.quantity,
        price: item.price,
      })),
    };

    console.log("paymentData payload: ", paymentData);

    try {
      const response = await fetch(`${BASE_URL}/initiate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentData),
      });

      if (!response.ok) {
        throw new Error("Failed to initiate payment");
      }

      const responseData = await response.json();
      const callbackUrl = `${window.location.origin}/qrcode`;
      const swishUrl = `swish://paymentrequest?token=${encodeURIComponent(
        responseData.token
      )}&callbackurl=${encodeURIComponent(callbackUrl)}`;

      console.log("[ChoosePayment] Opening Swish payment URL:", swishUrl);

      window.location.assign(swishUrl); // This opens the Swish app or tab

      // Start polling for payment status in the background
      startPollingPaymentStatus(responseData.id);
    } catch (error) {
      console.error(
        "[ChoosePayment] Error in initiateSwishPayment:",
        error.message
      );
    }
  };

  // Polling function for Swish payment status
  const startPollingPaymentStatus = (paymentId) => {
    console.log("[ChoosePayment] Starting payment status polling...");
    const interval = 2000;
    let attempts = 0;
    const maxAttempts = 10;

    intervalId = setInterval(async () => {
      if (attempts >= maxAttempts) {
        console.error(
          "[ChoosePayment] Maximum polling attempts reached, stopping."
        );
        clearPolling();
        alert("Unable to confirm payment status.");
        return;
      }

      // Check if redirection has already happened in another tab
      if (!canRedirect()) {
        console.log(
          "[ChoosePayment] Redirection already happened, stopping polling."
        );
        clearPolling();
        return;
      }

      try {
        const statusResponse = await getSwishPaymentStatus(paymentId);
        console.log(
          `[ChoosePayment] Polling attempt ${attempts + 1}. Payment status: ${
            statusResponse.status
          }`
        );

        if (statusResponse.status === "PAID") {
          console.log(
            "[ChoosePayment] Payment status is PAID, processing post-payment tasks..."
          );

          // Process the payment only once in the allowed tab
          if (canRedirect()) {
            console.log("[ChoosePayment] This tab is allowed to redirect.");

            await updateFridgeProductQuantities(cartItems, selectedFridgeId);
            await saveShoppingHistory(cartItems, user, selectedFridgeId, token);

            // Stop polling once the redirection happens
            clearPolling();

            // Set flag in localStorage to inform other tabs
            localStorage.setItem("paymentRedirected", "true");
            console.log("[ChoosePayment] Navigating to QR code page...");

            navigate("/qrcode", {
              state: { selectedFridgeId, paymentStatus: "PAID" },
              replace: true,
            });
          }
        }

        attempts++;
      } catch (error) {
        console.error("[ChoosePayment] Error checking payment status:", error);
        if (attempts >= maxAttempts) {
          console.error(
            "[ChoosePayment] Max attempts reached, stopping polling."
          );
          clearPolling();
          alert("Payment error, unable to confirm status.");
        }
      }
    }, interval);
  };

  // Function to stop polling and clear the interval
  const clearPolling = () => {
    console.log("[ChoosePayment] Clearing polling interval.");
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  };

  // Listen for changes in localStorage (from other tabs)
  useEffect(() => {
    const handleStorageChange = (e) => {
      console.log(
        `[ChoosePayment] Storage change detected. Key: ${e.key}, New Value: ${e.newValue}`
      );
      if (e.key === "paymentRedirected" && e.newValue === "true") {
        console.log(
          "[ChoosePayment] Redirection detected in another tab, stopping polling."
        );
        clearPolling();
        window.location.href = "/products"; // Redirect to products if another tab has redirected
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      clearPolling(); // Ensure polling is cleared if the component is unmounted
    };
  }, []);

  return (
    <Container>
      <Card onClick={initiateSwishPayment}>
        <Logo src={swishImage} alt="Swish" />
      </Card>
    </Container>
  );
};

export default ChoosePayment;

// Styled components
const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
`;

const Card = styled.div`
  width: 80%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const Logo = styled.img`
  width: 160px;
  height: 100px;
  object-fit: contain;
`;
