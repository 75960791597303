export const BASE_URL = "https://boxmealadminapinew.azurewebsites.net/api";
const baseProductUrl =
  "https://boxmealadminapinew.azurewebsites.net/api/product";
// const baseProductUrl = "https://localhost:7255/api/product";
// export const BASE_URL = "https://localhost:7255/api";
const getAllCategoriesEndpoint = `${baseProductUrl}/getallcategories`;
export const BASE_URL_IMAGE =
  "https://boxmealadmin.blob.core.windows.net/productimage/";

const baseFridgeUrl = "https://boxmealadminapinew.azurewebsites.net/api/fridge";

export const getAllProducts = async (fridgeId) => {
  const url = `${BASE_URL}/Fridge/${fridgeId}/products`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Include any other headers your API might need, like authorization
      },
    });

    // Check if the response is successful
    if (!response.ok) {
      // Handle HTTP errors
      const errorData = await response.json();
      console.error("Error response data:", errorData);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the response as JSON
    const data = await response.json();
    console.log("Fetched products:", data);

    return data;
  } catch (error) {
    // Handle network errors or any other issues
    console.error("Error fetching products:", error.message);
    throw error; // Re-throw the error after logging it for upstream handling
  }
};

export const getAllCategories = async () => {
  try {
    const response = await fetch(getAllCategoriesEndpoint);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const categories = await response.json();

    console.log("categories: ", categories);
    return categories;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

export const getFridgesByCustomerId = async (customerId) => {
  try {
    const response = await fetch(`${baseFridgeUrl}/customer/${customerId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching fridges:", error);
    throw error; // Propagate the error to the calling function
  }
};
